import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style.js';

const image = new CircleStyle({
    radius: 5,
    fill: null,
    stroke: new Stroke({color: 'red', width: 1}),
  });

const waldreservate_styles = {
    'Point': new Style({
      image: image,
    }),
    'LineString': new Style({
      stroke: new Stroke({
        color: 'green',
        width: 1,
      }),
    }),
    'MultiLineString': new Style({
      stroke: new Stroke({
        color: 'green',
        width: 1,
      }),
    }),
    'MultiPoint': new Style({
      image: image,
    }),
    'MultiPolygon': new Style({
      stroke: new Stroke({
        color: 'green',
        width: 1,
      }),
      fill: new Fill({
        color: 'green',
      }),
      zIndex: 10
    }),
    'Polygon': new Style({
      stroke: new Stroke({
        color: 'blue',
        lineDash: [4],
        width: 3,
      }),
      fill: new Fill({
        color: 'rgba(0, 0, 255, 0.1)',
      }),
    }),
    'GeometryCollection': new Style({
      stroke: new Stroke({
        color: 'magenta',
        width: 2,
      }),
      fill: new Fill({
        color: 'magenta',
      }),
      image: new CircleStyle({
        radius: 10,
        fill: null,
        stroke: new Stroke({
          color: 'magenta',
        }),
      }),
    }),
    'Circle': new Style({
      stroke: new Stroke({
        color: 'red',
        width: 2,
      }),
      fill: new Fill({
        color: 'rgba(255,0,0,0.2)',
      }),
    }),
  };

//   let styleCache = {};
// 
//   stylePattern(pattern, feature) {
//       console.log("Function Fired");
//       let style = styleCache[pattern];
//       if (!style) {
//         let ctx = document.createElement('canvas').getContext('2d');
//         let image = new Image();
//         style = new Style({
//             fill: new Fill({
//               color: 'transparent'
//             })
//           });
//         styleCache[pattern] = style;
//         image.onload = () => {
//           let createdPattern = ctx.createPattern(image, 'repeat');
//           style.getFill().setColor(createdPattern);
//           feature.changed();  // force a refresh or just wait until next render?
//         }
//         let patternSrc = "assets//static/images/patterns/" + pattern; // The pattern passed is the name of the PNG file
//         image.src = patternSrc;
//       }
//       return style;
//   }

var pattern
var cnv = document.createElement('canvas');
var ctx = cnv.getContext('2d');
var img = new Image();
img.src = '/static/images/patterns/pattern.png'; // your image here
img.onload = function() {
pattern = ctx.createPattern(img, 'repeat');
}

export {waldreservate_styles, pattern};