import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style.js';
import { pattern } from './waldreservate_style';

export const droneRestrictions_stylesFunction = function (feature) {
    // console.log(feature.values_);
  
    if (feature.values_.zone_name_de.includes("CTR")) {
      return new Style({
        stroke: new Stroke({
          color: 'red',
          width: 1,
        }),
        fill: new Fill({
          // color: 'rgba(255, 125, 0, 0.5)',
          color: pattern
        }),
        zIndex: 0
      });
    }
    else if (feature.values_.zone_reason_id === "NATURE") {
      return new Style({
        stroke: new Stroke({
          color: 'green',
          width: 1,
        }),
        fill: new Fill({
          color: 'rgba(55, 235, 52, 0.5)',
        }),
        zIndex: 5
      });
    }
    else if (feature.values_.zone_reason_id === "SENSITIVE") {
      return new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 1,
        }),
        fill: new Fill({
          color: 'rgba(0, 187, 255, 0.5)',
        }),
        zIndex: 10
      });
    }
    else   if (feature.values_.zone_reason_id === "AIR_TRAFFIC") {
      return new Style({
        stroke: new Stroke({
          color: 'red',
          width: 1,
        }),
        fill: new Fill({
          color: 'rgba(255, 81, 0, 0.5)',
        }),
        zIndex: 2
      });
    }
    // return droneRestrictions_styles[feature.getGeometry().getType()];
  };
  