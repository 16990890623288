import {Circle as CircleStyle, Fill, Icon, Stroke, Style} from 'ol/style.js';
import Point from 'ol/geom/Point';
import { pattern } from './waldreservate_style';



const iconmountainAirfields = 
    new Icon({
        anchor: [0.5, 0.5],
        // size: [52,52],
        // offset: [20, 0],
        // color: 'red',
        opacity: 1.0,
        scale: 0.15,
        src: `/static/images/icons/gebirgslandeplatz.svg`
    }); 


export const mountainAirfields_stylesFunction = function (feature, zoom) {

    if (feature.getGeometry().getType() === 'Point') {

        if (zoom>= 12){
            return new Style({
                image: iconmountainAirfields
                });
            }
        
        else {
            return new Style({
                image: new CircleStyle({
                  stroke: new Stroke({
                    color: 'blue',
                    width: 2,
                  }),
                  radius: 10,
                  fill: new Fill({color: 'blue'})
                })
              });
        } 
    }
}
    