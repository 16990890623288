import {Circle as CircleStyle, Fill, Icon, Stroke, Style} from 'ol/style.js';
import Point from 'ol/geom/Point';
import { pattern } from './waldreservate_style';


function iconObstacle(obstacletype) {
    return new Icon({
        anchor: [0.5, 0.5],
        // size: [52,52],
        // offset: [20, 0],
        // color: 'red',
        opacity: 1.0,
        scale: 0.2,
        src: `/static/images/icons/${obstacletype.toLowerCase()}.svg`
    }); 
}

export const aviationObstacles_stylesFunction = function (feature, zoom) {
    
    // ['TRANSMISSION_LINE', 'CRANE', 'POLE', 'BUILDING', 'VEGETATION',
    // 'STACK', 'CABLE_CAR', 'CATENARY', 'WINDMILL', 'BRIDGE']

    if (feature.getGeometry().getType() === 'Point') {

        if (zoom>= 12){
            return new Style({
                image: iconObstacle(feature.values_.obstacletype)
              });
        }
        else {
            return new Style({
                image: new CircleStyle({
                  stroke: new Stroke({
                    color: 'orange',
                    width: 2,
                  }),
                  radius: 5,
                  fill: new Fill({color: 'white'})
                })
              });
        }
    }
    else if (feature.getGeometry().getType() === 'LineString') {
        return new Style({
            stroke: new Stroke({
              color: 'blue',
              width: 2,
            }),
          });
    }
    else if (feature.getGeometry().getType() === 'MultiLineString') {
        return new Style({
            stroke: new Stroke({
              color: 'blue',
              width: 2,
            }),
          });
    }
    else if (feature.getGeometry().getType() === 'MultiPolygon') {
        return new Style({
            stroke: new Stroke({
              color: 'yellow',
              width: 1,
            }),
            fill: new Fill({
              color: 'rgba(255, 0, 0, 0.9)',
            }),
            zIndex: 10
          });
    }
    else if (feature.getGeometry().getType() === 'Polygon') {
        return new Style({
            stroke: new Stroke({
              color: 'yellow',
              width: 1,
            }),
            fill: new Fill({
              color: 'rgba(255, 0, 0, 0.9)',
            }),
            zIndex: 10
          });
    }

    return new Style({
        image: new CircleStyle({
          radius: 8,
          fill: new Fill({color: 'green'})
        })
      });

    
  };
  