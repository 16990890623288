import {Circle as CircleStyle, Fill, Icon, Stroke, Style} from 'ol/style.js';
import Point from 'ol/geom/Point';
import { pattern } from './waldreservate_style';



const iconNormalAirFields = 
    new Icon({
        anchor: [0.5, 0.5],
        // size: [52,52],
        // offset: [20, 0],
        // color: 'red',
        opacity: 1.0,
        scale: 0.075,
        src: `/static/images/icons/plane-up-solid.svg`
    }); 

const iconMilitaryAirFields = 
    new Icon({
        anchor: [0.5, 0.5],
        // size: [52,52],
        // offset: [20, 0],
        // color: 'red',
        opacity: 1.0,
        scale: 0.075,
        src: `/static/images/icons/fighterplane-up-solid.svg`
    }); 



export const airFields_stylesFunction = function (feature, zoom) {


    if (feature.getGeometry().getType() === 'Point') {

        if (zoom >= 12){
            if (feature.values_.icao.includes('LSM')) {
                return new Style({
                    image: iconMilitaryAirFields
                  });
            }
            else {
                return new Style({
                    image: iconNormalAirFields
                  });
            }
        }
        else {
            return new Style({
                image: new CircleStyle({
                  stroke: new Stroke({
                    color: 'blue',
                    width: 2,
                  }),
                  radius: 10,
                  fill: new Fill({color: 'white'})
                })
              });
        } 
    }
}