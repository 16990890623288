import './style.css';
import { Map, Overlay, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM, { ATTRIBUTION } from 'ol/source/OSM.js';
import { Attribution, ScaleLine, defaults as defaultControls } from 'ol/control.js';
import XYZ from 'ol/source/XYZ';
import proj4 from 'proj4';
import { register } from "ol/proj/proj4";
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON.js';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style.js';
import LayerGroup from 'ol/layer/Group';
import TileWMS from 'ol/source/TileWMS.js';
import Static from 'ol/source/ImageStatic.js';

import LayerSwitcher from 'ol-layerswitcher';
import { BaseLayerOptions, GroupLayerOptions } from 'ol-layerswitcher';

import { pattern, waldreservate_styles } from './helpers/styles/waldreservate_style';
import { droneRestrictions_stylesFunction } from './helpers/styles/drone_restriction_style';
import { aviationObstacles_stylesFunction } from './helpers/styles/aviation_obstacles_styles';
import { airFields_stylesFunction } from './helpers/styles/airfields_styles';
import { hospitalAirFields_stylesFunction } from './helpers/styles/hospital_styles';
import { mountainAirfields_stylesFunction } from './helpers/styles/mountain_airfields_styles';
import ImageLayer from 'ol/layer/Image';
import { fromLonLat } from 'ol/proj';
import Layer from 'ol/layer/Layer';
import CustomOverlay from './CustomOverlay.js';
import Select from 'ol/interaction/Select';
import { altKeyOnly, click, pointerMove, singleClick, always } from 'ol/events/condition.js';

/*
_________ .__            ____  __.            ___.    
\_   ___ \|  |__   ____ |    |/ _| ____   ____\_ |__  
/    \  \/|  |  \_/ __ \|      <  /    \ /  _ \| __ \ 
\     \___|   Y  \  ___/|    |  \|   |  (  <_> ) \_\ \
 \______  /___|  /\___  >____|__ \___|  /\____/|___  /
        \/     \/     \/        \/    \/           \/ 
*/


// let coords = [47.51592, 8.64225];
// let coords = [2690860, 1263705]
// let coords = [46.144397003532859, 6.029030502468904]
// let coords = [6.029030502468904, 46.144397003532859];
// let coords = [8.732604921065677, 47.49629643959691]
// let lang;

let langs = ["en", "de", "fr", "it"];

if (lang === undefined) {
  lang = langs[0];
}

console.log(lang);

let coords = [8.222665776, 46.800663464];
coords = proj4("EPSG:4326", "EPSG:3857", coords);
let center = [coords[0] - 0.2, coords[1]];



// console.log(coords);
var lastZoom = 10;

proj4.defs(
  "EPSG:2056",
  "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs"
);
register(proj4);

// Reprojected WMTS layer from map.geo.admin.ch
var createLayer = function (layername, timestam, fileformat, visibility, opacity) {
  return new TileLayer({
    type: 'base',
    visible: visibility,
    title: layername.replace('ch.swisstopo.', '') + ` - ${opacity}`,
    opacity: opacity / 100,
    zIndex: 0,
    source: new XYZ({
      attributions: '<a target="new" href="https://www.swisstopo.admin.ch/">data and map &copy; swisstopo</a>',
      opaque: false,
      url: `https://wmts.geo.admin.ch/1.0.0/${layername}/default/current/3857/{z}/{x}/{y}.${fileformat}`
    })
  });
}
// https://leaflet-tilelayer-swiss.karavia.ch/layers.html
const colorBaseLayerSwissTopo = createLayer('ch.swisstopo.pixelkarte-farbe', 'current', 'jpeg', false, 100);
const swTLM3dBaseLayerSwissTopo = createLayer('ch.swisstopo.swisstlm3d-karte-grau', 'current', 'png', false, 100);
const clrTLM3dBaseLayerSwissTopo = createLayer('ch.swisstopo.swisstlm3d-karte-farbe', 'current', 'png', false, 100);
const swWinterLayerSwissTopo = createLayer('ch.swisstopo.pixelkarte-farbe-winter', 'current', 'jpeg', false, 100);
const swBaseLayerSwissTopo = createLayer('ch.swisstopo.pixelkarte-grau', 'current', 'jpeg', false, 100);
const swBase50LayerSwissTopo = createLayer('ch.swisstopo.pixelkarte-grau', 'current', 'jpeg', true, 50);
const colorBase50LayerSwissTopo = createLayer('ch.swisstopo.pixelkarte-farbe', 'current', 'jpeg', false, 50);


const styleFunction = function (feature) {
  return waldreservate_styles[feature.getGeometry().getType()];
};

const styleFunctionObstacles = function (feature) {
  return aviationObstacles_stylesFunction(feature, map.getView().getZoom());
};

const styleFunctionAirFields = function (feature) {
  return airFields_stylesFunction(feature, map.getView().getZoom());
};

const styleFunctionHospitalAirFields = function (feature) {
  return hospitalAirFields_stylesFunction(feature, map.getView().getZoom());
};

const styleFunctionMountainAirfields = function (feature) {
  return mountainAirfields_stylesFunction(feature, map.getView().getZoom());
};

// let overlay = new Overlay({
//   id: 'parking_spots',
//   visible: true,
//   title: 'parking spots',
//   position: center,
//   positioning: 'center-center',
//   element: document.getElementById('app_feature2'),
//   stopEvent: false,
//   zIndex: 10000,
// });

// let parkingSpots = new Layer({
//   title: 'parking spots',
//   visible: false,
//   source: overlay
// });

const easyFlyZone30 = new TileLayer({
  title: 'Easy Fly Zone 30m Distance',
  visible: false,
  source: new TileWMS({
    url: 'https://qgis.bko.li/ogc/drones?',
    params: { 'LAYERS': 'headachefree_dronezone_30', 'TILED': true },
    serverType: 'geoserver',
    transition: 0,
  }),
});

const easyFlyZone150 = new TileLayer({
  title: 'Easy Fly Zone 150m Distance',
  visible: false,
  source: new TileWMS({
    url: 'https://qgis.bko.li/ogc/drones?',
    params: { 'LAYERS': 'headachefree_dronezone_150', 'TILED': true },
    serverType: 'geoserver',
    transition: 0,
  }),
});

// https://openlayers.org/en/latest/examples/geojson.html
const droneRestrictions = new VectorLayer({
  style: droneRestrictions_stylesFunction,
  title: 'drone restrictions',
  source: new VectorSource({
    projection: 'EPSG:4326',
    url: '/media/data/ch.bazl.einschraenkungen-drohnen.geojson',
    format: new GeoJSON()
  })
})

const aviationObstacles = new VectorLayer({
  style: styleFunctionObstacles,
  title: 'aviation obstacles',
  source: new VectorSource({
    projection: 'EPSG:4326',
    url: '/media/data/ch.bazl.luftfahrthindernis.geojson',
    format: new GeoJSON()
  })
})

const proNaturaNatureReservates = new VectorLayer({
  style: styleFunction,
  title: 'pronatura nature reservates',
  source: new VectorSource({
    projection: 'EPSG:4326',
    url: '/media/data/ch.pronatura.naturschutzgebiete.geojson',
    format: new GeoJSON()
  })
})

const proNaturaWoodReservates = new VectorLayer({
  style: styleFunction,
  title: 'pronatura wood reservates',
  source: new VectorSource({
    projection: 'EPSG:4326',
    url: '/media/data/ch.pronatura.waldreservate.geojson',
    format: new GeoJSON()
  })
})

const bafuaWoodReservates = new VectorLayer({
  style: styleFunction,
  title: 'bafu wood reservates',
  source: new VectorSource({
    projection: 'EPSG:4326',
    url: '/media/data/ch.bafu.waldreservate.geojson',
    format: new GeoJSON()
  })
})

const mountainAirfields = new VectorLayer({
  style: styleFunctionMountainAirfields,
  title: 'mountain airfields',
  source: new VectorSource({
    projection: 'EPSG:4326',
    url: '/media/data/ch.bazl.gebirgslandeplaetze.geojson',
    format: new GeoJSON()
  })
})

const hospitalLandingFields = new VectorLayer({
  style: styleFunctionHospitalAirFields,
  title: 'hospital landing fields',
  source: new VectorSource({
    projection: 'EPSG:4326',
    url: '/media/data/ch.bazl.spitallandeplaetze.geojson',
    format: new GeoJSON()
  })
})

const airfieldsHeliports = new VectorLayer({
  style: styleFunctionAirFields,
  title: 'airfields-heliports',
  source: new VectorSource({
    projection: 'EPSG:4326',
    url: '/media/data/ch.bazl.flugplaetze-heliports.geojson',
    format: new GeoJSON()
  })
})

const parkingSpotsSource = new TileWMS({
  url: 'https://qgis.bko.li/ogc/dach_parking',
  params: { 'LAYERS': 'dach_parking_all', 'TILED': true },
  serverType: 'geoserver',
  // Countries have transparency, so do not fade tiles:
  transition: 0,
});

const parkingSpots = new TileLayer({
  visible: false,
  title: 'parking spots',
  source: parkingSpotsSource
});

// Extent of the SMAP data bounds 
const imageextent = [5.779211663571768, 45.69079016187699, 10.53772807691217, 47.996394549824288];

const rebuild = false;

const notamDabsToday = new ImageLayer({
  visible: true,
  title: 'notam dabs today',
  opacity: 1.0,
  zIndex: 100,
  source: new Static({
    url: '/media/data/today_page-0_red_t.png',
    imageExtent: imageextent,
    projection: 'EPSG:4326',
    crossOrigin: ''
  }),
});

const notamDabsTomorrow = new ImageLayer({
  visible: rebuild,
  title: 'notam dabs tomorrow',
  opacity: 1.0,
  zIndex: 99,
  source: new Static({
    url: '/media/data/tomorrow_page-0_red_t.png',
    imageExtent: imageextent,
    projection: 'EPSG:4326',
    crossOrigin: ''
  }),
});



const baseMaps = new LayerGroup({
  // A layer must have a title to appear in the layerswitcher
  title: 'Base maps',
  layers: [
    swTLM3dBaseLayerSwissTopo, colorBaseLayerSwissTopo, colorBase50LayerSwissTopo, swBaseLayerSwissTopo, swBase50LayerSwissTopo, swWinterLayerSwissTopo, clrTLM3dBaseLayerSwissTopo
  ]
});

const natureLayers = new LayerGroup({
  visible: false,
  title: 'Nature and forest reserves',
  layers: [proNaturaNatureReservates, proNaturaWoodReservates, bafuaWoodReservates]
});

const aviationLayers = new LayerGroup({
  visible: false,
  title: 'Aviation',
  layers: [mountainAirfields, hospitalLandingFields, airfieldsHeliports]
});

const otherLayers = new LayerGroup({
  title: 'Layers',
  layers: [natureLayers, aviationLayers, parkingSpots, aviationObstacles, droneRestrictions, easyFlyZone150, easyFlyZone30, notamDabsTomorrow, notamDabsToday]
});

var map = new Map({
  target: 'map',
  view: new View({
    projection: "EPSG:3857",
    // constrainResolution: true,
    // center: fromLonLat([longitude, latitude]),
    center: coords,
    zoom: lastZoom
  }),
  layers: [baseMaps, otherLayers],
  controls: defaultControls().extend([
    new ScaleLine({
      units: "metric"
    })
  ]),
});


// map.addOverlay(parkingSpots);
let resolution = map.getView().getResolution();
// overlay.setPosition([center[0] + 0*resolution, center[1] + 300*resolution]); 


const layerSwitcher = new LayerSwitcher({
  reverse: true,
  groupSelectStyle: 'group'
});
map.addControl(layerSwitcher);


// const hideTxt = document.getElementById('hide_txt').onclick = function() {
//   document.getElementById("landing").style.display = "none";
//   document.getElementById("map").style.top = "75px";
//   document.getElementById('hide_txt').style.display = "none";
//   map.updateSize();
// }

// *************************************************************************//
// Layer Popup and Infos                                                    //
// https://codesandbox.io/s/popup-forked-jm19g?file=/main.js                //
// *************************************************************************//
let popup = document.getElementById('popup');

const popupOverlay = new Overlay({
  element: popup,
  offset: [9, 9]
});

map.addOverlay(popupOverlay);



async function getDataFromGeoAdmin(point, cWidth, cHeight, layername, extent) {
  var url = ` https://api3.geo.admin.ch/rest/services/all/MapServer/identify?geometry=${point}&returnGeometry=false&geometryType=esriGeometryPoint&imageDisplay=${cWidth},${cHeight},96&lang=fr&layers=all:${layername}&mapExtent=${extent}&tolerance=5&sr=3857`
  console.log(url);

  return await fetch(url)
    .then((response) => response.text())
    .then((html) => {
      // content.innerHTML = html;
      // console.log(html);
      return html;
    });
}

map.on('click', (event) => {
  console.log('move');
  let features = [];
  const valuesToShow = [];
  map.forEachFeatureAtPixel(event.pixel,
    (feature, layer) => {
      // console.log(feature)  
      //console.log(layer);
      features = feature.values_;
      // console.log(features);
      // console.log(typeof(features));
      var txt;

      if (layer.values_.title === droneRestrictions.values_.title) {

        let days = feature.values_.auth_intervalbefore.replace('P', '').replace('DT', 'D').replace('D', 'days').replace('H', 'hours')
        if (days.trim() != '') {
          days = `Min. ${days} before.`
        }

        txt = `<b>${feature.values_['zone_name_' + lang]}</b><br>${feature.values_['zone_restriction_' + lang]}<br><hr style="border-top: dotted 2px;" />` +
          `<small>${feature.values_['zone_message_' + lang]} &nbsp; ${days}<br>${feature.values_['auth_name_' + lang]}` +
          `<br><a href="${feature.values_['auth_url_' + lang]}" target="_blank" style="color:blue;">${feature.values_['auth_url_' + lang]}</a>`

        const optionalFeatures = [feature.values_.auth_contact, feature.values_.auth_service_en, feature.values_.auth_email, feature.values_.auth_phone]

        for (const ftr of optionalFeatures) {
          if (ftr.trim() !== "") {
            txt += `<br>${ftr}`
          }
        }
        txt += `</small>`

        valuesToShow.push(txt);
      }
      if (layer.values_.title === aviationObstacles.values_.title) {
        txt = `<b>${feature.values_.obstacletype}</b><br>Height: ${feature.values_.maxheightagl} m`;
        valuesToShow.push(txt);
      }
      if (layer.values_.title === proNaturaNatureReservates.values_.title) {
        txt = `<b>Nature reserve ${feature.values_.label}</b><br>`;
        valuesToShow.push(txt);
      }
      if (layer.values_.title === proNaturaWoodReservates.values_.title) {
        txt = `<b>Wood reserve ${feature.values_['mcpfe_class_' + lang]}</b><br>`;
        valuesToShow.push(txt);
      }
      if (layer.values_.title === bafuaWoodReservates.values_.title) {
        txt = `<b>Wood reserve ${feature.values_.name}</b><br>${feature.values_['mcpfe_class_' + lang]}<br>`;
        valuesToShow.push(txt);
      }
      if (layer.values_.title === mountainAirfields.values_.title) {
        txt = `<b>Mountain airfield: ${feature.values_.icao} - ${feature.values_.name}</b><br>`;
        valuesToShow.push(txt);
      }
      if (layer.values_.title === hospitalLandingFields.values_.title) {
        txt = `<b>Hospital landing fields: ${feature.values_.label}</b><br>`;
        valuesToShow.push(txt);
      }
      if (layer.values_.title === airfieldsHeliports.values_.title) {
        txt = `<b>Airfield / Heliport: ${feature.values_.icao} - ${feature.values_.name}</b><br>`;
        valuesToShow.push(txt);
      }

    },
    {
      layerFilter: (layer) => {
        return (layer.type === new VectorLayer().type) ? true : false;
      }, hitTolerance: 6
    }
  );
  if (!features || features.length === 0) {
    popup.innerHTML = '';
    popup.hidden = true;
  }
  else {
    popup.innerHTML = `${valuesToShow.join('<hr size="3"><br> ')}`;
    popup.hidden = false;
    popupOverlay.setPosition(event.coordinate);
  }


});




var appFeatureVisible = false;


map.on('postrender', function (e) {

  if ((parkingSpots.getVisible() || notamDabsTomorrow.getVisible()) && !appFeatureVisible) {
    appFeatureVisible = true;
    showHideAppFeature();
  }
  if (!parkingSpots.getVisible() && !notamDabsTomorrow.getVisible()) {
    appFeatureVisible = false;
    showHideAppFeature();
  }
});


function showHideAppFeature() {
  if (appFeatureVisible) {
    document.getElementById('app_feature').style.display = 'block';
  }
  else {
    document.getElementById('app_feature').style.display = 'none';
    parkingSpots.setVisible(false);
    notamDabsTomorrow.setVisible(false);
  }
}

document.getElementById('closeAppFeature').onclick = function () {
  document.getElementById('app_feature').style.display = 'none';
  parkingSpots.setVisible(false);
  notamDabsTomorrow.setVisible(false);
};

